
import { mapState } from 'vuex'
import { RoutesListFn } from '@/router'
export default {
  data() {
    return {
      RoutesList: RoutesListFn(this.$route.params.site),
      moduleName: 'home',
      site: this.$route.params.site,
      lang: this.$route.params.lang,
      curSubItemName: '', // 当前选中的二级菜单
      current: -1,
    }
  },
  computed: {
    ...mapState({
      isAgree: (state) => state.cookie_agree.agree,
      cookieBtnDisagree: (state) => state.cookie_btn_disagree,
      locale: (state) => state.locale,
      isHdFtMobile: (state) => state.isHdFtMobile,
    }),
    isCollapsed() {
      return this.isAgree || this.cookieBtnDisagree
    },
    getSubItem() {
      const lang = this.$route.params.lang

      return (subItem) => {
        return (
          subItem?.children.filter((item) => {
            return !item?.hidden?.hiddenLangList?.includes(lang)
          }) || []
        )
      }
    }
  },
  mounted() {},
  created() {
    this.setDefaultActive()
  },
  methods: {
    switchItem(switchKey) {
      if (process.client && switchKey) {
        return window?.[switchKey] || false
      }
      return false
    },
    setDefaultActive() {
      // 选中
      const fullPath = this.$route.path
      const path = fullPath.split('?')[0]
      const moduleName = path.split('/')[3]
      this.moduleName = moduleName || 'home'
    },
    openDropDown(e, item, index) {
      this.current = index
      // const rect = e.target?.getBoundingClientRect()
      if (item?.children?.length > 0) {
        this.curSubItemName = item.children[0].name
        this.$nextTick(() => {
          // const dom = e.target.querySelector('.sub_list')
          // if (dom) dom.style.left = `-${rect.left}px`
        })
      } else {
        // this.$nextTick(() => {
        //   const wrap = e.target.querySelector('.checkList_content_wrap')
        //   if (wrap) wrap.style.left = `-${rect.left}px`
        // })
      }
    },
    changeCurSubItemName(item) {
      this.curSubItemName = item.name
    },
    closeDropDown(e, item) {
      this.curSubItemName = ''
      this.current = -1
    },
  },
}


import { mapState } from 'vuex'
export default {
  props: {
    menuItem: {
      type: Object,
      default: () => {
        return {}
      },
    },
    isCollapsed: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      searchInput: '',
    }
  },
  computed: {
    ...mapState({
      isAgree: (state) => state.cookie_agree.agree,
      cookieBtnDisagree: (state) => state.cookie_btn_disagree,
    }),
  },
  methods: {
    checkOrder() {
      this.$router.push({
        name: 'site-lang-support-querySupport-waybill',
        params: {
          site: this.$route.params.site,
          lang: this.$route.params.lang,
        },
        query: {
          No: this.searchInput,
        },
      })
    },
  },
}


export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    handleJump() {
      this.item.trackFn && this.item.trackFn()
    },
  },
}

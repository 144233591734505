import { render, staticRenderFns } from "./input.vue?vue&type=template&id=16ec4664&scoped=true&"
import script from "./input.vue?vue&type=script&lang=js&"
export * from "./input.vue?vue&type=script&lang=js&"
import style0 from "./input.vue?vue&type=style&index=0&id=16ec4664&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16ec4664",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LinkTo: require('/app/workspace/DEPLOY/ibu-owt-core/ibu-owt-core-owt-ssr-nuxt/components/LinkTo.vue').default,SvgIcon: require('/app/workspace/DEPLOY/ibu-owt-core/ibu-owt-core-owt-ssr-nuxt/components/SvgIcon.vue').default})
